<template>
  <a-card class="main-content">
    <template slot="title">
      <span class="table-title">路由配置</span>
      <a-button type="primary" icon="plus" @click="showModal('add')">新增</a-button>
    </template>
    <a-table
      :loading="loading"
      rowKey="id"
      :columns="columns"
      :data-source="datalist">
      <span slot="action" slot-scope="record">
        <a-button type="link">编辑</a-button>
        <a-popconfirm
          title="您确认要删除该组件吗？"
          @confirm="del(record.id)">
          <a-button type="link">删除</a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <a-modal
      :title="modalType == 'add' ? '新增配置' : '修改配置'"
      :visible="modal"
      :confirm-loading="confirmLoading"
      @ok="modalConfirm"
      @cancel="() => {
        this.$refs.modalForm.resetFields();
        this.modal = false;
      }">
      <a-form-model
        ref="modalForm"
        :model="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }">
        <a-form-model-item label="Name" prop="name" required>
          <a-input v-model="form.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Path" prop="path" required>
          <a-input v-model="form.path"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Component" prop="import" required>
          <a-input v-model="form.import"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      columns: [
        { title: '序号', key: 'id', dataIndex: 'id', align: 'center', width: 65},
        { title: '名称', key: 'name', dataIndex: 'name' },
        { title: '地址', key: 'path', dataIndex: 'path' },
        { title: '引入组件', key: 'import', dataIndex: 'import' },
        { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action'} }
      ],
      datalist: [],
      // 弹框
      modalType: '',
      modal: false,
      confirmLoading: false,
      form: {
        name: '',
        path: '',
        import: ''
      }
    }
  },
  mounted () {
    this.getRouteConfigList()
  },
  methods: {
    getRouteConfigList () {
      this.loading = true
      this.api.getRouteConfigList().then(res => {
        this.datalist = res.data.data 
        this.loading = false;
      }).catch(() => {
        this.loading = false 
      })
    },
    showModal (type, routeId) {
      this.modalType = type 
      this.modal = true
    },
    modalConfirm () {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style>

</style>